import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { deleteNote, updateNote } from "./NotesActions";
import { Button } from "react-bootstrap";

class Note extends Component {
  onDeleteClick = () => {
    const { note } = this.props;
    this.props.deleteNote(note.id);
  };
  onUpperCaseClick = () => {
    const { note } = this.props;
    this.props.updateNote(note.id, {
      bronto_needs: note.bronto_needs.toUpperCase()
    });
  };
  onLowerCaseClick = () => {
    const { note } = this.props;
    this.props.updateNote(note.id, {
      bronto_needs: note.bronto_needs.toLowerCase()
    });
  };
  render() {
    const { note } = this.props;
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const yyyy = date.getFullYear();
      const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
      const dd = String(date.getDate()).padStart(2, '0');
      const hh = String(date.getHours()).padStart(2, '0');
      const min = String(date.getMinutes()).padStart(2, '0');
      const ss = String(date.getSeconds()).padStart(2, '0');
      return `${yyyy}/${mm}/${dd} - ${hh}:${min}:${ss}`;
    };
    return (
      <div style={{ wordWrap: 'break-word', overflowWrap: 'break-word' }}>
        <hr />
        <p>
          (<strong>ID de oferta:</strong> {note.id})<br/>
          (<strong>Visibilidad:</strong> {note.bronto_visibility})<br/>
          <strong>Fecha Creacion:</strong> {formatDate(note.created_at)}<br/>
          <strong style={{ color: '#180dab' }}>🎃 Alguien Ofrece:</strong> {note.bronto_offers}<br/>
          <strong style={{ color: '#167f70' }}>👻 Alguien Necesita:</strong> {note.bronto_needs}<br/>          
          <strong>Datos de Contacto:</strong> {note.bronto_contact}<br/>
          <strong>País:</strong> {note.bronto_country}<br/>
          <strong>Ciudad:</strong> {note.bronto_city}
        </p>
      </div>
    );
  }
}

Note.propTypes = {
  note: PropTypes.object.isRequired
};
const mapStateToProps = state => ({});

export default connect(mapStateToProps, { deleteNote, updateNote })(
  withRouter(Note)
);
