import axios from "axios";
import { toast } from "react-toastify";

import 'react-toastify/dist/ReactToastify.css';


// Configure toast notifications globally
toast.configure({
  autoClose: false, // Disable auto close globally
  closeOnClick: false, // Prevent closing on click globally
  closeButton: true, // Show close button globally
});

export const setAxiosAuthToken = token => {
  if (typeof token !== "undefined" && token) {
    // Apply for every request
    axios.defaults.headers.common["Authorization"] = "Token " + token;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];
  }
};

export const toastOnError = error => {
  const toastOptions = {
    autoClose: false, // milliseconds
    closeOnClick: true, // Allow closing on click
    closeButton: true, // Show close button
  };
  
  if (error.response) {
    // known error
    const errorMessage = error.response.data.message || 'Un error ocurrió. Por favor intenta de nuevo.';
    const errorDetails = JSON.stringify(error.response.data);
    toast.error(`${errorMessage} (Detalles: ${errorDetails})`);
  } else if (error.message) {
    toast.error(`${error.message} (Detalles: ${JSON.stringify(error)})`);
  } else {
    toast.error(`Un error inesperado ocurrió. Por favor intenta de nuevo. (Detalles: ${JSON.stringify(error)})`);
  }
};


export const isEmpty = value =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);
