import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import {
  Alert,
  Container,
  Button,
  Row,
  Col,
  Form,
  FormControl
} from "react-bootstrap";

import PropTypes from "prop-types";

import { logout } from "../login/LoginActions";

import brontoBanner from "../../images/4_banner_verticalname_small_bronto.png";
import CustomNavbar from "../navbar/Navbar";

import motemonCopyright from "../../images/7_motemon_copyright.png";

class ResetPasswordConfirm extends Component {
  onLogout = () => {
    this.props.logout();
  };
  
  constructor(props) {
    super(props);
    this.state = {
      new_password: "",
      passwordError: "",
      status: ""
    };
  }
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSaveClick = () => {
    this.setState({ passwordError: "" });
    this.setState({ status: "" });

    const { uid, token } = this.props.match.params;
    const data = {
      uid: uid,
      token: token,
      new_password: this.state.new_password
    };
    axios
      .post("/api/v1/users/reset_password_confirm/", data)
      .then(response => {
        this.setState({ status: "success" });
      })
      .catch(error => {
        if (
          error.response &&
          error.response.data.hasOwnProperty("new_password")
        ) {
          this.setState({ passwordError: error.response.data["new_password"] });
        } else {
          this.setState({ status: "error" });
        }
      });
  };

  render() {
    const { auth } = this.props;
    const user = auth?.user || {};
    const isLoggedIn = user.username?.trim().length > 0 ? true : false;
    
    const errorAlert = (
      <Alert variant="danger">
        <Alert.Heading>Problema durante reseteo de password.</Alert.Heading>
        <p>
          Por favor, intenta <Link to="/send_reset_password">resetear tu password</Link> de nuevo
          o contacta a soporte ( support@brontomarket.com ) para obtener ayuda adicional.
        </p>
      </Alert>
    );

    const successAlert = (
      <Alert variant="success">
        <Alert.Heading>Reseteo de Password</Alert.Heading>
        <p>
          Ya puedes hacer <Link to="/login/">Login</Link> a tu cuenta con tu nuevo password.
        </p>
      </Alert>
    );

    const form = (
      <div>
        <Form>
          <Form.Group controlId="emailId">
            <Form.Label>Tu Nuevo Password</Form.Label>
            <Form.Control
              isInvalid={this.state.passwordError}
              type="password"
              name="new_password"
              placeholder="Ingresa tu nuevo password"
              value={this.state.new_password}
              onChange={this.onChange}
            />
            <FormControl.Feedback type="invalid">
              {this.state.passwordError}
            </FormControl.Feedback>
          </Form.Group>
        </Form>
        <Button color="primary" onClick={this.onSaveClick}>
          Guardar Cambios
        </Button>
      </div>
    );

    let alert = "";
    if (this.state.status === "error") {
      alert = errorAlert;
    } else if (this.state.status === "success") {
      alert = successAlert;
    }

    return (
      <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh", alignItems: "center" }}>
          <CustomNavbar isLoggedIn={isLoggedIn} user={user} onLogout={this.onLogout} />
          <img
            src={brontoBanner}
            alt="BrontoBanner"
            style={{ width: "30%", minWidth: "180px", maxWidth: "333px", height: "auto", margin: "20px 0 20px 0" }}
          />
        <Container>
          <Row>
            <Col md="6">
              <h2>Resetea tu Password</h2>
              {alert}
              {this.state.status !== "success" && form}
            </Col>
          </Row>
        </Container>
        <footer style={{ textAlign: "center", marginTop: "auto", padding: "1rem 0", marginBottom: "2rem" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={motemonCopyright}
              alt="motemonCopyright"
              style={{ width: "85px", height: "auto", margin: "0" }}
            />
            <p style={{ margin: "0px" }}>2024</p>
          </div>
        </footer>
      </div>
    );
  }
}

ResetPasswordConfirm.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  logout
})(withRouter(ResetPasswordConfirm));