import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { getNotes } from "./NotesActions";
import Note from "./Note";

class NotesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      input1: '',
      input2: '',
      input_country: '',
      input_city: '',
      showFilters: false,
      page: 1, // Add page state
      hasMore: true, // Add hasMore state to check if more data is available
      showPagination: false // Add showPagination state to control visibility of pagination
    };
    this.brontoBannerRef = React.createRef();
  }

  handleInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSearch = () => {
    this.setState({ page: 1, showPagination: true }, () => { // Show pagination after search
      const { input1, input2, input_country, input_city, page } = this.state;
      this.props.getNotes(input1, input2, input_country, input_city, page);
      this.scrollToBanner();
    });
  };

  scrollToBanner = () => {
    const brontoBanner = this.brontoBannerRef.current;
    if (brontoBanner) {
      const totalHeight = (brontoBanner.offsetHeight + 
                          parseInt(window.getComputedStyle(brontoBanner).marginTop) + 
                          parseInt(window.getComputedStyle(brontoBanner).marginBottom) + 
                          parseInt(window.getComputedStyle(brontoBanner).borderTopWidth) + 
                          parseInt(window.getComputedStyle(brontoBanner).borderBottomWidth)) * 2;
      
      const startPosition = window.pageYOffset;
      const distance = totalHeight - startPosition;
      const duration = 555;
      let start = null;

      const step = (timestamp) => {
        if (!start) start = timestamp;
        const progress = timestamp - start;
        const progressPercentage = Math.min(progress / duration, 1);
        window.scrollTo(0, startPosition + distance * progressPercentage);
        if (progress < duration) {
          window.requestAnimationFrame(step);
        }
      };

      window.requestAnimationFrame(step);
    }
  };

  handleFilterToggle = () => {
    this.setState((prevState) => ({
      showFilters: !prevState.showFilters
    }));
  };

  focusInput = (inputId) => {
    document.getElementById(inputId).focus();
  };

  addShadow = (inputId) => {
    document.getElementById(inputId).style.boxShadow = '0 1px 6px rgba(32, 33, 36, 0.28)';
  };

  removeShadow = (inputId) => {
    document.getElementById(inputId).style.boxShadow = 'none';
  };

  loadNextPage = () => {
    
    this.setState((prevState) => ({
      page: prevState.page + 1
    }), () => {
      const { input1, input2, input_country, input_city, page } = this.state;
      this.props.getNotes(input1, input2, input_country, input_city, page);
      this.scrollToBanner(); // Scroll to top after loading next page
    });
  };

  loadPreviousPage = () => {
    this.setState((prevState) => ({
      page: prevState.page > 1 ? prevState.page - 1 : 1
    }), () => {
      const { input1, input2, input_country, input_city, page } = this.state;
      this.props.getNotes(input1, input2, input_country, input_city, page);
      this.scrollToBanner(); // Scroll to top after loading previous page
    });
  };

  componentDidUpdate(prevProps) {
    //console.log("componentDidUpdate(prevProps) executed"); 
    //console.log("prevProps.notes: ", prevProps.notes);
    //console.log("this.props.notes: ", this.props.notes);
    if (prevProps.notes !== this.props.notes) {
      if (this.props.notes.notes.length === 0) {
        this.setState({ hasMore: false });
      } else {
        this.setState({ hasMore: true });
      }
    }
  }


  render() {
    const { notes } = this.props.notes;
    const { showFilters, page, hasMore, showPagination } = this.state;

    let items;
    if (notes.length === 0) {
      items = (
        <div>          
          <p style={{ display: 'inline' }}>¡Recuerda! Si no encuentras lo que buscas, ¡puedes&nbsp;<a href="/dashboard/" style={{ textDecoration: "underline" }}>crear nuevas ofertas</a>&nbsp;(totalmente GRATIS)!<br/><br/>
          Solamente haz clic en&nbsp;<a href="/dashboard/" style={{ textDecoration: "underline" }}>"¡Ofrecer!"</a>&nbsp;y&nbsp;</p>
          <h1 style={{ fontSize: '1rem', fontWeight: 'normal', display: 'inline' }}>¡comienza a intercambiar o ganar dinero incluso mientras duermes!
          </h1>
        </div>
      );
    } else {
      items = notes.map(note => {
        return <Note key={note.id} note={note} />;
      });
    }

    const inputStyle = {
      width: '100%',
      padding: '10px 20px',
      margin: '0px 0',
      boxSizing: 'border-box',
      border: '1px solid #dfe1e5',
      borderRadius: '24px',
      fontSize: '16px',
      outline: 'none',
      transition: 'box-shadow 0.05s ease-in-out',
    };
    
    const inputStyleFilters = {
      width: '100%',
      padding: '10px 7px', // '10px 20px' goes with border-radius 24px
      margin: '2px', // so the shadow looks ok
      boxSizing: 'border-box',
      border: '1px solid #dfe1e5',
      borderRadius: '10px', // 24px goes well with padding '10px 20px'
      fontSize: '16px',
      outline: 'none',
      transition: 'box-shadow 0.05s ease-in-out',
    };

    const inputFocusStyle = {
      boxShadow: '0 1px 6px rgba(32, 33, 36, 0.28)',
    };

    const buttonStyle = {
      backgroundColor: '#f8f9fa',
      border: '1px solid #dfe1e5',
      borderRadius: '4px',
      color: '#5f6368',
      fontSize: '14px',
      //height: '36px',
      lineHeight: '27px',
      margin: '11px 4px',
      padding: '8px 4px', //'0 8px',
      cursor: 'pointer',
      transition: 'background-color 0.05s ease'
    };

    const buttonHoverStyle = {
      backgroundColor: '#f1f3f4',
      borderColor: '#c6c6c6'
    };

    const buttonActiveStyle = {
      backgroundColor: '#e8e8e8',
      borderColor: '#a6a6a6'
    };

    const flexContainerStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '10px',
      maxWidth: '600px', // Ensure the total width does not exceed 600px
      margin: '0 auto' // Center the container
    };

    const halfWidthStyle = {
      flex: '1',
      maxWidth: 'calc(50% - 5px)' // Adjust width to fit within the container
    };

    const buttonContainerStyle = {
      display: 'flex',
      justifyContent: 'center',
      gap: '10px',
      margin: '20px 0'
    };

    const filterContainerStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      gap: '6px', // not 10px since 4px are padding for filter's shadows
      overflow: 'hidden',
      transition: 'max-height 0.555s ease-in-out, opacity 0.555s ease-in-out',
      maxHeight: showFilters ? '500px' : '0', // Adjust based on your content
      opacity: showFilters ? '1' : '0',
      maxWidth: '600px',
      margin: '0 auto' // Center the container
    };

    const adjustedInputStyle = {
      ...inputStyleFilters,
      width: 'calc(100% - 4px)' // Adjust width to fit within the container
    };

    const pageIndicatorStyle = {
      color: '#167f70',
      fontSize: '18px',
      lineHeight: '36px'
    };

    return (
      <div>
        <div ref={this.brontoBannerRef} style={{ maxWidth: '600px', margin: '0 auto' }}>
          <label htmlFor="input2"><strong style={{ color: '#180dab' }}>🎃 ¿Qué necesitas?</strong> </label>
          <br/>
          <div style={{ position: 'relative' }}>
            <svg 
              viewBox="0 0 24 24" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg" 
              style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', width: '20px', height: '20px', fill: '#999' }}
              onClick={() => this.focusInput('input2')}
              onMouseOver={() => this.addShadow('input2')}
              onMouseOut={() => this.removeShadow('input2')}
            >
              <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" fill="#757575"/>
            </svg>
            <input
              type="text"
              id="input2"
              name="input2"
              value={this.state.input2}
              onChange={this.handleInputChange}
              placeholder="(dólares, libros de arte, clases, etc...)"
              style={{ ...inputStyle, paddingLeft: '35px' }}
              onFocus={(e) => e.currentTarget.style.boxShadow = inputFocusStyle.boxShadow}
              onBlur={(e) => e.currentTarget.style.boxShadow = 'none'}
              onMouseOver={(e) => e.currentTarget.style.boxShadow = inputFocusStyle.boxShadow}
              onMouseOut={(e) => e.currentTarget.style.boxShadow = 'none'}
            />
          </div>
        </div>
        <div style={{ maxWidth: '600px', margin: '0 auto' }}>
          <br/>
          <label htmlFor="input1"><strong style={{ color: '#167f70' }}>👻 ¿Qué ofreces? </strong></label>
          <br/>
          <div style={{ position: 'relative' }}>
            <svg 
              viewBox="0 0 24 24" 
              fill="none" 
              xmlns="http://www.w3.org/2000/svg"
              style={{ position: 'absolute', top: '50%', left: '10px', transform: 'translateY(-50%)', width: '20px', height: '20px', fill: '#999' }}
              onClick={() => this.focusInput('input1')}
              onMouseOver={() => this.addShadow('input1')}
              onMouseOut={() => this.removeShadow('input1')}
            >
              <path d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" fill="#757575"/>
            </svg>
            <input
              type="text"
              id="input1"
              name="input1"
              value={this.state.input1}
              onChange={this.handleInputChange}
              placeholder="(dólares, libros de arte, clases, etc...)"
              style={{ ...inputStyle, paddingLeft: '35px' }}
              onFocus={(e) => e.currentTarget.style.boxShadow = inputFocusStyle.boxShadow}
              onBlur={(e) => e.currentTarget.style.boxShadow = 'none'}
              onMouseOver={(e) => e.currentTarget.style.boxShadow = inputFocusStyle.boxShadow}
              onMouseOut={(e) => e.currentTarget.style.boxShadow = 'none'}
            />
          </div>
        </div>
        <br/>
        <div className="filter-container" style={filterContainerStyle}>
          <div style={halfWidthStyle}>
            <label htmlFor="input_country"><strong>¿País?</strong> </label>
            <br/>
            <div style={{ position: 'relative' }}>
              <input
                type="text"
                id="input_country"
                name="input_country"
                value={this.state.input_country}
                onChange={this.handleInputChange}
                placeholder="Todos los Países"
                style={{ ...adjustedInputStyle, paddingLeft: '7px' }}
                onFocus={(e) => e.currentTarget.style.boxShadow = inputFocusStyle.boxShadow}
                onBlur={(e) => e.currentTarget.style.boxShadow = 'none'}
                onMouseOver={(e) => e.currentTarget.style.boxShadow = inputFocusStyle.boxShadow}
                onMouseOut={(e) => e.currentTarget.style.boxShadow = 'none'}
              />
            </div>
          </div>
          <div style={halfWidthStyle}>
            <label htmlFor="input_city"><strong>¿Ciudad?</strong> </label>
            <br/>
            <div style={{ position: 'relative' }}>
              <input
                type="text"
                id="input_city"
                name="input_city"
                value={this.state.input_city}
                onChange={this.handleInputChange}
                placeholder="Todas las Ciudades"
                style={{ ...adjustedInputStyle, paddingLeft: '7px' }}
                onFocus={(e) => e.currentTarget.style.boxShadow = inputFocusStyle.boxShadow}
                onBlur={(e) => e.currentTarget.style.boxShadow = 'none'}
                onMouseOver={(e) => e.currentTarget.style.boxShadow = inputFocusStyle.boxShadow}
                onMouseOut={(e) => e.currentTarget.style.boxShadow = 'none'}
              />
            </div>
          </div>
        </div>
        <div style={buttonContainerStyle}>
          <button
            style={buttonStyle}
            onMouseOver={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
            onMouseOut={(e) => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
            onMouseDown={(e) => e.currentTarget.style.backgroundColor = buttonActiveStyle.backgroundColor}
            onMouseUp={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
            onClick={this.handleSearch}
          >
            <strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;¡Búsqueda!&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong>
          </button>
          <button
            style={buttonStyle}
            onMouseOver={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
            onMouseOut={(e) => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
            onMouseDown={(e) => e.currentTarget.style.backgroundColor = buttonActiveStyle.backgroundColor}
            onMouseUp={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
            onClick={this.handleFilterToggle}
          >
            <strong>{showFilters ? '\u00A0\u00A0Ocultar\u00A0\u00A0Filtros\u00A0\u00A0' : '\u00A0\u00A0Seleccionar País\u00A0\u00A0'}</strong>
          </button>
        </div>
        {items}
        {showPagination && (
          <div style={{ textAlign: 'center', margin: '20px 0', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '10px' }}>
            <button
              style={buttonStyle}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
              onMouseDown={(e) => e.currentTarget.style.backgroundColor = buttonActiveStyle.backgroundColor}
              onMouseUp={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
              onClick={this.loadPreviousPage}
              disabled={page === 1}
            >
              &lt; Página Anterior
            </button>
            <span style={pageIndicatorStyle}>{page}</span>
            <button
              style={buttonStyle}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = buttonStyle.backgroundColor}
              onMouseDown={(e) => e.currentTarget.style.backgroundColor = buttonActiveStyle.backgroundColor}
              onMouseUp={(e) => e.currentTarget.style.backgroundColor = buttonHoverStyle.backgroundColor}
              onClick={this.loadNextPage}
              disabled={!hasMore}
            >
              Siguiente Página &gt;
            </button>
          </div>
        )}
        <hr />
      </div>
    );
  }
}

NotesList.propTypes = {
  getNotes: PropTypes.func.isRequired,
  notes: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  notes: state.notes
});

export default connect(mapStateToProps, {
  getNotes
})(withRouter(NotesList));