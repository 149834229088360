import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import { addNote } from "./NotesActions";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

class AddNote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bronto_needs: "",
      bronto_offers: "",
      bronto_contact: "",
      bronto_country: "",
      bronto_city: "",
      charCount: {
        bronto_needs: 0,
        bronto_offers: 0,
        bronto_contact: 0,
        bronto_country: 0,
        bronto_city: 0,
      },
      errors: {
        bronto_needs: false,
        bronto_offers: false,
        bronto_contact: false,
      },
      showAddNote: false,
      maxOffers: 2,
      availableAfter: null,
    };
  }

  // Function to remove emojis
  removeEmojis = (text) => {
    return text.replace(/[\u{1F600}-\u{1F64F}]/gu, '') // Emoticons
               .replace(/[\u{1F300}-\u{1F5FF}]/gu, '') // Symbols & Pictographs
               .replace(/[\u{1F680}-\u{1F6FF}]/gu, '') // Transport & Map Symbols
               .replace(/[\u{1F700}-\u{1F77F}]/gu, '') // Alchemical Symbols
               .replace(/[\u{1F780}-\u{1F7FF}]/gu, '') // Geometric Shapes Extended
               .replace(/[\u{1F800}-\u{1F8FF}]/gu, '') // Supplemental Arrows-C
               .replace(/[\u{1F900}-\u{1F9FF}]/gu, '') // Supplemental Symbols and Pictographs
               .replace(/[\u{1FA00}-\u{1FA6F}]/gu, '') // Chess Symbols
               .replace(/[\u{1FA70}-\u{1FAFF}]/gu, '') // Symbols and Pictographs Extended-A
               .replace(/[\u{2600}-\u{26FF}]/gu, '') // Miscellaneous Symbols
               .replace(/[\u{2700}-\u{27BF}]/gu, ''); // Dingbats
  };

  componentDidMount() {
    this.showAddNoteValidation();
  }

  showAddNoteValidation = async () => {
    try {
      const response = await axios.get('/api/v1/check_offers_created_last_hour/', {
        params: { user_id: this.props.user.id }
      });
      const { count, available_after, utc_offset } = response.data;
      console.log("utc_offset: ",utc_offset)
      console.log("available_after: ",available_after)
      const availableAfterLocal = new Date(available_after).toLocaleString('en-US', {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
      });
      if (count >= this.state.maxOffers) {
        this.setState({
          showAddNote: false,
          availableAfter: availableAfterLocal,
        });
      } else {
        this.setState({ showAddNote: true });
      }
    } catch (error) {
      console.error("Error checking offers:", error);
    }
  };

  onChange = e => {
    const { name, value } = e.target;
    const sanitizedValue = this.removeEmojis(value);
    const words = sanitizedValue.split(' ').map(word => word.charAt(0) + word.slice(1).toLowerCase());
    const sentenceCaseValue = words.join(' ');
    this.setState({
      [name]: sentenceCaseValue,
      charCount: {
        ...this.state.charCount,
        [name]: sanitizedValue.length,
      },
      errors: {
        ...this.state.errors,
        [name]: false,
      },
    });
  };

  validateFields = () => {
    const { bronto_needs, bronto_offers, bronto_contact } = this.state;
    const errors = {
      bronto_needs: !bronto_needs,
      bronto_offers: !bronto_offers,
      bronto_contact: !bronto_contact,
    };
    this.setState({ errors });
    return !errors.bronto_needs && !errors.bronto_offers && !errors.bronto_contact;
  };

  onAddClick = async () => {
    await this.showAddNoteValidation();  // Perform validation again before proceeding
    if (this.state.showAddNote) {
      if (this.validateFields()) {
        const note = {
          bronto_needs: this.state.bronto_needs,
          bronto_offers: this.state.bronto_offers,
          bronto_contact: this.state.bronto_contact,
          bronto_country: this.state.bronto_country,
          bronto_city: this.state.bronto_city,
        };
        await this.props.addNote(note);
        this.setState({
          bronto_needs: "",
          bronto_offers: "",
          bronto_contact: "",
          bronto_country: "",
          bronto_city: "",
          charCount: {
            bronto_needs: 0,
            bronto_offers: 0,
            bronto_contact: 0,
            bronto_country: 0,
            bronto_city: 0,
          },
          errors: {
            bronto_needs: false,
            bronto_offers: false,
            bronto_contact: false,
          },
        });
        await this.showAddNoteValidation();  // Ensure validation is called after offer creation
        toast.success("¡Oferta creada exitosamente!");
      } else {
        toast.error("Por favor diligencia los campos obligatorios para crear tu oferta.");
      }
    } else {
      toast.error(`¡Puedes crear ${this.state.maxOffers} ofertas GRATIS cada hora!`);
    }
  };

  render() {
    const { errors, showAddNote, availableAfter, maxOffers } = this.state;
    return (
      <div>
        {showAddNote ? (
          <div>
            <h2>Crea Una Nueva Oferta!</h2>
            <Form>
              <Form.Group controlId="bronto_needs_Id">
                <Form.Label style={{ color: errors.bronto_needs ? 'red' : 'black' }}>¿Qué Necesitas? (Obligatorio)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="bronto_needs"
                  placeholder="(dólares, libros de arte, clases, etc...)"
                  value={this.state.bronto_needs}
                  onChange={this.onChange}
                  maxLength={50}
                />
                <Form.Text className="skiptranslate">{this.state.charCount.bronto_needs}/50</Form.Text>
              </Form.Group>
              <Form.Group controlId="bronto_offers_Id">
                <Form.Label style={{ color: errors.bronto_offers ? 'red' : 'black' }}>¿Qué Ofreces? (Obligatorio)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="bronto_offers"
                  placeholder="(dólares, libros de arte, clases, etc...)"
                  value={this.state.bronto_offers}
                  onChange={this.onChange}
                  maxLength={50}
                />
                <Form.Text className="skiptranslate">{this.state.charCount.bronto_offers}/50</Form.Text>
              </Form.Group>
              <Form.Group controlId="bronto_contact_Id">
                <Form.Label style={{ color: errors.bronto_contact ? 'red' : 'black' }}>Información de Contacto (Obligatorio)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={1}
                  name="bronto_contact"
                  placeholder="(Whatsapp, email, teléfono, etc)"
                  value={this.state.bronto_contact}
                  onChange={this.onChange}
                  maxLength={30}
                />
                <Form.Text className="skiptranslate">{this.state.charCount.bronto_contact}/30</Form.Text>
              </Form.Group>
              <Form.Group controlId="bronto_countryId">
                <Form.Label>País (Opcional)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={1}
                  name="bronto_country"
                  placeholder="(Dejar vacío si es una oferta Global)"
                  value={this.state.bronto_country}
                  onChange={this.onChange}
                  maxLength={20}
                />
                <Form.Text className="skiptranslate">{this.state.charCount.bronto_country}/20</Form.Text>
              </Form.Group>
              <Form.Group controlId="bronto_cityId">
                <Form.Label>Ciudad (Opcional)</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={1}
                  name="bronto_city"
                  placeholder="(Dejar vacío si es para cualquier Ciudad)"
                  value={this.state.bronto_city}
                  onChange={this.onChange}
                  maxLength={20}
                />
                <Form.Text className="skiptranslate">{this.state.charCount.bronto_city}/20</Form.Text>
              </Form.Group>
            </Form>
            <Button variant="success" onClick={this.onAddClick}>
              ¡Crear Oferta!
            </Button>
          </div>
        ) : (
          <div>
            <p>
              <br/>
              Puedes crear {maxOffers} ofertas <strong>GRATIS cada hora</strong>, ¡las 24 horas del día!
              <br/><br/>
              Para crear más ofertas <strong>regresa después</strong> de esta hora: {availableAfter}
            </p>
          </div>
        )}
      </div>
    );
  }
}

AddNote.propTypes = {
  addNote: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, { addNote })(withRouter(AddNote));
