import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Container } from "react-bootstrap";
import { logout } from "./login/LoginActions";
import NotesList from "./notes_for_everyone/NotesList";
import brontoBanner from "../images/4_banner_verticalname_small_bronto.png";
import CustomNavbar from "./navbar/Navbar";

import motemonCopyright from "../images/7_motemon_copyright.png";

class Home extends Component {
  onLogout = () => {
    this.props.logout();
  };

  render() {
    const { auth } = this.props;
    const user = auth?.user || {};
    const isLoggedIn = user.username?.trim().length > 0 ? true : false;

    return (
      <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh", alignItems: "center" }}>
        <CustomNavbar isLoggedIn={isLoggedIn} user={user} onLogout={this.onLogout} />
        <img
          src={brontoBanner}
          alt="BrontoBanner"
          style={{ width: "30%", minWidth: "180px", maxWidth: "333px", height: "auto", margin: "20px 0 20px 0" }}
        />
        <Container style={{ flex: "1", textAlign: "center" }}>
          <NotesList />
        </Container>
        <footer style={{ textAlign: "center", marginTop: "auto", padding: "1rem 0", marginBottom: "2rem" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={motemonCopyright}
              alt="motemonCopyright"
              style={{ width: "85px", height: "auto", margin: "0" }}
            />
            <p style={{ margin: "0px" }}>2024</p>
          </div>
        </footer>
      </div>
    );
  }
}

Home.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  logout,
})(withRouter(Home));
