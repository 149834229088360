import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { Alert, Container, Row, Col } from "react-bootstrap";

import PropTypes from "prop-types";

import { logout } from "../login/LoginActions";

import brontoBanner from "../../images/4_banner_verticalname_small_bronto.png";
import CustomNavbar from "../navbar/Navbar";

import motemonCopyright from "../../images/7_motemon_copyright.png";

class ActivateAccount extends Component {
  onLogout = () => {
    this.props.logout();
  };
  
  constructor(props) {
    super(props);
    this.state = {
      status: ""
    };
  }
  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount() {
    const { uid, token } = this.props.match.params;

    axios
      .post("/api/v1/users/activation/", { uid, token })
      .then(response => {
        this.setState({ status: "success" });
      })
      .catch(error => {
        this.setState({ status: "error" });
      });
  }

  render() {
    const { auth } = this.props;
    const user = auth?.user || {};
    const isLoggedIn = user.username?.trim().length > 0 ? true : false;
    
    let errorAlert = (
      <Alert variant="danger">
        <Alert.Heading>Problema durante activación de cuenta</Alert.Heading>
        Por favor, intenta de nuevo o contacta a soporte ( support@brontomarket.com ) para obtener ayuda adicional.        
      </Alert>
    );

    let successAlert = (
      <Alert variant="success">
        <Alert.Heading>Tu cuenta ha sido activada!</Alert.Heading>
        <p>
          Puedes hacer <Link to="/login/">Login</Link> a tu cuenta.
        </p>
      </Alert>
    );

    let alert = "";
    if (this.state.status === "error") {
      alert = errorAlert;
    } else if (this.state.status === "success") {
      alert = successAlert;
    }

    return (
      <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh", alignItems: "center" }}>
          <CustomNavbar isLoggedIn={isLoggedIn} user={user} onLogout={this.onLogout} />
          <img
            src={brontoBanner}
            alt="BrontoBanner"
            style={{ width: "30%", minWidth: "180px", maxWidth: "333px", height: "auto", margin: "20px 0 20px 0" }}
          />
        <Container>
          <Row>
            <Col md="6">
              <h2>Activar Cuenta</h2>
              {alert}
            </Col>
          </Row>
        </Container>
        <footer style={{ textAlign: "center", marginTop: "auto", padding: "1rem 0", marginBottom: "2rem" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={motemonCopyright}
              alt="motemonCopyright"
              style={{ width: "85px", height: "auto", margin: "0" }}
            />
            <p style={{ margin: "0px" }}>2024</p>
          </div>
        </footer>
      </div>
    );
  }
}

ActivateAccount.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  logout
})(withRouter(ActivateAccount));
