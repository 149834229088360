import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Container, Button, Row, Col, Form } from "react-bootstrap";

import { login } from "./LoginActions.js";
import { logout } from "./LoginActions";

import brontoBanner from "../../images/4_banner_verticalname_small_bronto.png";
import CustomNavbar from "../navbar/Navbar";

import motemonCopyright from "../../images/7_motemon_copyright.png";

class Login extends Component {
  onLogout = () => {
    this.props.logout();
  };
  
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: ""
    };
  }

  onChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: name === "email" ? value.toLowerCase() : value });
  };

  onLoginClick = () => {
    const userData = {
      email: this.state.email,
      password: this.state.password
    };
    this.props.login(userData, "/dashboard");
  };

  render() {
    const { auth } = this.props;
    const user = auth?.user || {};
    const isLoggedIn = user.username?.trim().length > 0 ? true : false;
    
    return (
      <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh", alignItems: "center" }}>
        <CustomNavbar isLoggedIn={isLoggedIn} user={user} onLogout={this.onLogout} />
        <img
          src={brontoBanner}
          alt="BrontoBanner"
          style={{ width: "30%", minWidth: "180px", maxWidth: "333px", height: "auto", margin: "20px 0 20px 0" }}
        />
        <Container>
          <Row>
            <Col md="6">
              <h2>Acceder</h2>
              <Form>
                <Form.Group controlId="emailId">
                  <Form.Label style={{ margin: "0" }}>Email</Form.Label>
                  <Form.Control
                    type="text"
                    name="email"
                    placeholder="Inserta tu email"
                    value={this.state.email}
                    onChange={this.onChange}
                  />
                </Form.Group>

                <Form.Group controlId="passwordId">
                  <Form.Label style={{ margin: "0" }}>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="password"
                    placeholder="Inserta tu contraseña"
                    value={this.state.password}
                    onChange={this.onChange}
                  />
                </Form.Group>
              </Form>
              <Button color="primary" onClick={this.onLoginClick}>
                Acceder
              </Button>
              <p className="mt-2">
                No tienes usuario? <Link to="/signup">Crear Usuario GRATIS</Link>
              </p>
              <p className="mt-2" style={{ margin: "0" }}>
                Olvidaste tu Password?{" "}
                <Link to="/send_reset_password">Resetear Password</Link>
              </p>
            </Col>
          </Row>
        </Container>
        <footer style={{ textAlign: "center", marginTop: "auto", padding: "1rem 0", marginBottom: "2rem" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={motemonCopyright}
              alt="motemonCopyright"
              style={{ width: "85px", height: "auto", margin: "0" }}
            />
            <p style={{ margin: "0px" }}>2024</p>
          </div>
        </footer>
      </div>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  login, logout
})(withRouter(Login));
