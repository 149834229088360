import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import axios from "axios";
import { setAxiosAuthToken } from "../../utils/Utils";
import {
  Alert,
  Container,
  Button,
  Row,
  Col,
  Form,
  FormControl
} from "react-bootstrap";

import { logout } from "../login/LoginActions";

import brontoBanner from "../../images/4_banner_verticalname_small_bronto.png";
import CustomNavbar from "../navbar/Navbar";

import motemonCopyright from "../../images/7_motemon_copyright.png";

class Signup extends Component {
  onLogout = () => {
    this.props.logout();
  };
  
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      email: "",
      usernameError: "",
      passwordError: "",
      emailError: "",
      status: ""
    };
  }

  onChange = e => {
    const { name, value } = e.target;
    this.setState({ [name]: name === "email" ? value.toLowerCase() : value });
  };

  onSignupClick = () => {
    this.setState({
      usernameError: "",
      emailError: "",
      passwordError: "",
      status: ""
    });
    
    var local_custom_language = localStorage.getItem('custom_language');

    const userData = {
      username: this.state.username,
      password: this.state.password,
      email: this.state.email,
      custom_language : local_custom_language
    };

    setAxiosAuthToken(""); // send request with empty token
    axios
      .post("/api/v1/users/", userData)
      .then(response => {
        this.setState({ status: "success" });
      })
      .catch(error => {
        if (error.response) {
          if (error.response.data.hasOwnProperty("username")) {
            this.setState({ usernameError: error.response.data["username"] });
          }
          if (error.response.data.hasOwnProperty("email")) {
            this.setState({ emailError: error.response.data["email"] });
          }
          if (error.response.data.hasOwnProperty("password")) {
            this.setState({ passwordError: error.response.data["password"] });
          }
          if (error.response.data.hasOwnProperty("detail")) {
            this.setState({ status: "error" });
          }
        } else {
          this.setState({ status: "error" });
        }
      });
  };

  render() {
    const { auth } = this.props;
    const user = auth?.user || {};
    const isLoggedIn = user.username?.trim().length > 0 ? true : false;
    
    let errorAlert = (
      <Alert variant="danger">
        <Alert.Heading>Problema durante creación de usuario. </Alert.Heading>
        Por favor intente de nuevo o contacte a soporte (support@brontomarket.com ) para ayuda adicional.        
      </Alert>
    );

    let successAlert = (
      <Alert variant="success">
        <Alert.Heading>Usuario creado!</Alert.Heading>
        <p>
          Acabamos de enviar un correo con un link de activación de tu usuario. Por favor revisa tu email.          
        </p>
      </Alert>
    );

    const form = (
      <div>
        <Form>
          <Form.Group controlId="usernameId">
            <Form.Label style={{ margin: "0" }}>Nombre de Usuario</Form.Label>
            <Form.Control
              isInvalid={this.state.usernameError}
              type="text"
              name="username"
              placeholder="Ingresa tu nombre de usuario"
              value={this.state.username}
              onChange={this.onChange}
            />
            <FormControl.Feedback type="invalid">
              {this.state.usernameError}
            </FormControl.Feedback>
          </Form.Group>

          <Form.Group controlId="emailId">
            <Form.Label style={{ margin: "0" }}>Email</Form.Label>
            <Form.Control
              isInvalid={this.state.emailError}
              type="text"
              name="email"
              placeholder="Ingresa tu email"
              value={this.state.email}
              onChange={this.onChange}
            />
            <FormControl.Feedback type="invalid">
              {this.state.emailError}
            </FormControl.Feedback>
          </Form.Group>

          <Form.Group controlId="passwordId">
            <Form.Label style={{ margin: "0" }}>Password</Form.Label>
            <Form.Control
              isInvalid={this.state.passwordError}
              type="password"
              name="password"
              placeholder="Ingresa tu contraseña"
              value={this.password}
              onChange={this.onChange}
            />
            <Form.Control.Feedback type="invalid">
              {this.state.passwordError}
            </Form.Control.Feedback>
          </Form.Group>
        </Form>
        <Button color="primary" onClick={this.onSignupClick}>
          Registrarse
        </Button>
      </div>
    );

    let alert = "";
    if (this.state.status === "error") {
      alert = errorAlert;
    } else if (this.state.status === "success") {
      alert = successAlert;
    }

    return (
      <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh", alignItems: "center" }}>
        <CustomNavbar isLoggedIn={isLoggedIn} user={user} onLogout={this.onLogout} />
        <img
          src={brontoBanner}
          alt="BrontoBanner"
          style={{ width: "30%", minWidth: "180px", maxWidth: "333px", height: "auto", margin: "20px 0 20px 0" }}
        />
        <Container>
          <Row>
            <Col md="6">
              <h2 style={{ margin: "0" }}>Crear Usuario</h2>
              {alert}
              {this.state.status !== "success" && form}
              <p className="mt-2">
                Ya tienes un usuario? <Link to="/login">Acceder</Link>
              </p>
            </Col>
          </Row>
        </Container>
        <footer style={{ textAlign: "center", marginTop: "auto", padding: "1rem 0", marginBottom: "2rem" }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <img
              src={motemonCopyright}
              alt="motemonCopyright"
              style={{ width: "85px", height: "auto", margin: "0" }}
            />
            <p style={{ margin: "0px" }}>2024</p>
          </div>
        </footer>
      </div>
    );
  }
}

Signup.propTypes = {
  logout: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps, {
  logout
})(withRouter(Signup));
